import React from "react"

import SEO from "../components/seo"
import styled from "styled-components"

const Main = styled.main`
  display: flex;
  justify-content: center;
`

const Col = styled.div`
  max-width: 1200px;
  margin-top: 2rem;
`

const NotFoundPage = () => (
  <Main>
    <Col>
      <SEO title="404: Not found" />
      <h1>Sorry</h1>
      <p>We couldn&#39;t find that page.</p>
    </Col>
  </Main>
)

export default NotFoundPage
